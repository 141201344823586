<template>
  <v-container fluid>
    <v-card class="pa-5 mt-3">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("requests.requests")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :items="nodes"
                    item-text="caption"
                    item-value="nodeGuid"
                    :rules="[rules.required]"
                    v-model="editedItem.nodeGuid"
                    @change="nodeChanged(editedItem.nodeGuid)"
                    :label="$t('nodes.caption')"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    :items="selectedReports"
                    item-text="reportCaption"
                    item-value="reportGuid"
                    :rules="[rules.required]"
                    v-model="editedItem.reportGuid"
                    :label="$t('requests.reportCaption')"
                  ></v-select>
                </v-col>
                <v-dialog
                  ref="dialog"
                  v-model="modal1"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="parameterData.fromDate"
                      :rules="[rules.required]"
                      :label="$t('fromDate')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="parameterData.fromDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="modal1 = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <!-- :return-value.sync="date" -->
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="parameterData.toDate"
                      :rules="[rules.required]"
                      :label="$t('toDate')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="parameterData.toDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="modal2 = false">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-col cols="12" md="12">
                  <v-text-field
                    persistent-hint
                    v-model="editedItem.description"
                    :label="$t('requests.description')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>
      <v-data-table
        :headers="headers"
        :items="requests"
        :search="search"
        :items-per-page="10"
        :loading="loading"
        :loading-text="$t('loading')"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ requestStatus[item.status] }}
          </v-chip>
        </template>
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ item.createdDate | moment("YYYY-MM-DD - hh:mm A") }}
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="dialog = !dialog">
              {{ $t("add") }}
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="showReport(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Preview") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mx-1"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="red darken-2"
                  icon
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import moment from "moment";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      selectedReports: [],
      loading: true,
      valid: true,
      modal1: false,
      modal2: false,
      dialog: false,
      dialogDelete: false,
      dialogChangePassword: false,
      editedIndex: -1,
      search: "",
      parameterData: {
        fromDate: moment(new Date()).format("yyyy-MM-DD"),
        toDate: moment(new Date()).format("yyyy-MM-DD"),
      },
      requestStatus: {
        0: this.$t("requestStatus.0"),
        1: this.$t("requestStatus.1"),
        2: this.$t("requestStatus.2"),
      },

      headers: [
        {
          text: this.$t("requests.reportCaption"),
          value: "report.reportCaption",
        },
        { text: this.$t("fromDate"), value: "fromDate" },
        { text: this.$t("toDate"), value: "toDate" },
        { text: this.$t("requests.description"), value: "description" },
        { text: this.$t("requests.status"), value: "status" },
        { text: this.$t("nodes.nodes"), value: "node.caption" },
        { text: this.$t("date"), value: "createdDate" },
        { text: "", value: "actions" },
      ],
      requests: [],
      reports: [],
      nodes: [],
      stateType: 0,
      editedItem: {},
      defaultItem: {
        requestGuid: "00000000-0000-0000-0000-000000000000",
        parameterData: "",
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
    this.getReports();
    this.getNodes();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    nodeChanged(nodeGuid) {
      var productGuid = this.nodes.filter(function (obj) {
        return obj.nodeGuid == nodeGuid;
      })[0].productGuid;

      this.selectedReports = this.reports.filter(
        (report) => report.productGuid === productGuid
      );
    },
    editItem(item) {
      this.editedIndex = this.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // var obj = JSON.parse(this.editedItem.parameterData);
      // this.editedItem.parameterData.fromDate = obj.fromDate;
      // this.editedItem.parameterData.toDate = obj.toDate;
      if (this.editedItem.status == 0) this.dialog = true;
    },
    getColor(state) {
      if (state == 2) return "green";
      if (state == 1) return "primary";
      return "orange";
    },
    deleteItem(item) {
      this.editedIndex = this.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.status == 0) this.dialogDelete = true;
    },
    showReport(item) {
      this.editedIndex = this.requests.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.status == 2) {
        this.$router.push({
          name: "requests.show",
          params: { requestGuid: this.editedItem.requestGuid },
          // props: { vType: this.editedItem.billType },
        });
      } else {
        this.$toast.error(this.$t("error.ReportNotReady"));
      }
      // let routeData = this.$router.resolve({
      //   name: "requests.show",
      //   // params: { reqGuid: this.editedItem.requestGuid },
      //   params: { reqGuid: "this.editedItem.requestGuid" },
      //   // query: { reqGuid: JSON.stringify(voucher) },
      // });
      // window.open(routeData.href, "_blank");
    },
    deleteItemConfirm() {
      var deleteItem = this.requests[this.editedIndex];
      axios
        .delete("requests/Delete?id=" + deleteItem.requestGuid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },
    refreshTable() {
      axios
        .get("requests/Get")
        .then((response) => {
          this.requests = response.data.data;

          //set from/to dates from parameterData
          this.requests.forEach(function (element) {
            element.fromDate = JSON.parse(element.parameterData).fromDate;
            element.toDate = JSON.parse(element.parameterData).toDate;
          });

          this.parentrequest = response.data.data; // what is this?
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getReports() {
      axios
        .get("Reports/Get")
        .then((response) => {
          this.reports = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getNodes() {
      axios
        .get("Nodes/Get")
        .then((response) => {
          this.nodes = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.selectedReports = [];
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.requests[this.editedIndex];
          updatedItem.requestGuid = this.editedItem.requestGuid;
          updatedItem.parameterData = JSON.stringify(this.parameterData);
          updatedItem.description = this.editedItem.description;
          updatedItem.reportGuid = this.editedItem.reportGuid;
          updatedItem.nodeGuid = this.editedItem.nodeGuid;
          axios
            .post("requests/Save", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
                this.refreshTable();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.requests[this.editedIndex], this.editedItem);
        } else {
          this.editedItem.parameterData = JSON.stringify(this.parameterData);
          axios
            .post("requests/Save", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
                this.refreshTable();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>
