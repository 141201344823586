var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-5 mt-3"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("requests.requests")))])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.nodes,"item-text":"caption","item-value":"nodeGuid","rules":[_vm.rules.required],"label":_vm.$t('nodes.caption')},on:{"change":function($event){return _vm.nodeChanged(_vm.editedItem.nodeGuid)}},model:{value:(_vm.editedItem.nodeGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "nodeGuid", $$v)},expression:"editedItem.nodeGuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.selectedReports,"item-text":"reportCaption","item-value":"reportGuid","rules":[_vm.rules.required],"label":_vm.$t('requests.reportCaption')},model:{value:(_vm.editedItem.reportGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportGuid", $$v)},expression:"editedItem.reportGuid"}})],1),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required],"label":_vm.$t('fromDate'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.parameterData.fromDate),callback:function ($$v) {_vm.$set(_vm.parameterData, "fromDate", $$v)},expression:"parameterData.fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.parameterData.fromDate),callback:function ($$v) {_vm.$set(_vm.parameterData, "fromDate", $$v)},expression:"parameterData.fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required],"label":_vm.$t('toDate'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.parameterData.toDate),callback:function ($$v) {_vm.$set(_vm.parameterData, "toDate", $$v)},expression:"parameterData.toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.parameterData.toDate),callback:function ($$v) {_vm.$set(_vm.parameterData, "toDate", $$v)},expression:"parameterData.toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" OK ")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"persistent-hint":"","label":_vm.$t('requests.description')},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.requests,"search":_vm.search,"items-per-page":10,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.requestStatus[item.status])+" ")])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdDate,"YYYY-MM-DD - hh:mm A"))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" "),_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.showReport(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Preview")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red darken-2","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }